import React, { useEffect, useState } from 'react'
import Header from './Header'
import { Link } from 'react-router-dom';
import Footer4 from './Footer4';

function Bodycare() {

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            setTimeout(() => {
                setIsLoading(false);
            }, 1000);
        };

        fetchData();
    }, []);

    const items = [
        { id: 1, name: 'Forchics', imageUrl: './images/cards/01-Forchics-Card.jpg', imageUrl2: './images/Card-modal/01-Forchics-Modal.jpg', url: 'https://forchics.com/' },
        { id: 7, name: 'Venom Scent', imageUrl: './images/cards/02-venomscent-Card.jpg', imageUrl2: './images/Card-modal/02-venomscent-Modal.jpg', url: 'https://venomscent.com/' },
        { id: 16, name: 'Vue Swiss', imageUrl: "./images/cards/07-Vue-Swiss-Card.jpg", imageUrl2: "./images/Card-modal/07-Vue-Swiss-Modal.jpg", url: '/' },
        { id: 19, name: 'Bleame', imageUrl: "./images/cards/06-Bleame-Card.jpg", imageUrl2: "./images/Card-modal/06-Bleame-Modal.jpg", url: 'https://bleame.com/' },
        { id: 20, name: 'Meow Meow Tweet', imageUrl: "./images/cards/37-Meow-Meow-Tweet-Card.jpg", imageUrl2: "./images/Card-modal/37-Meow-Meow-Tweet-Modal.jpg", url: 'https://meowmeowtweet.com/' },
        { id: 52, name: 'Jajaaracosmetics', imageUrl: "./images/cards/52.jpg", imageUrl2: "./images/Card-modal/52-modal.jpg", url: 'https://blackoutwicks.com/' },
        { id: 55, name: 'Nudestix', imageUrl: "./images/cards/55.jpg", imageUrl2: "./images/Card-modal/55-modal.jpg", url: 'https://www.nudestix.com/' },
    ];

    return (
        <>


            <div>
                {isLoading ? (
                    <div id="loader-main">
                        <div id="loader" className="loader">
                            <div id="loader-2">
                                <div id="loader">
                                    <div l="" a="" />
                                    <div l="" b="" />
                                    <div l="" c="" />
                                    <div l="" d="" />
                                    <div l="" e="" />
                                    <div l="" f="" />
                                    <div l="" g="" />
                                    <div l="" h="" />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <>

                        <Header />

                        <div className='p-3'>
                            <Link to="/shopify"><button className="btn btn-dark" style={{ backgroundColor: "#000" }}>Back</button></Link>
                        </div>

                        <h1 className="text-center portfoil-main-head">
                            Body Care / Skin / Cosmetic / Beauty / Body Massage
                        </h1>
                        <main className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    {items.map(item => (
                                        <div key={item.id} className="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-6 card-main">
                                            {/* <!-- Card --> */}
                                            <div className="card card-main-content">
                                                <img className="card-img-top" src={item.imageUrl} alt={item.name} type="button"
                                                    data-bs-toggle="modal" data-bs-target={`#exampleModal-${item.id}`}
                                                    loading="lazy" />
                                                <div className="card-body">
                                                    <div className="card-title">Shopify</div>
                                                    <p className="card-text">
                                                        <b>{item.name}</b>
                                                    </p>
                                                </div>
                                            </div>
                                            {/* <!-- Modal --> */}
                                            <div className="modal fade p-0" id={`exampleModal-${item.id}`} tabIndex="-1"
                                                aria-labelledby={`exampleModalLabel-${item.id}`} aria-hidden="true">
                                                <div className="d-flex justify-content-end modal-btn-main">
                                                    <button type="button" className=" btn-close p-2 modal-btn m-3" data-bs-dismiss="modal"
                                                        aria-label="Close"></button>
                                                </div>
                                                <div className="modal-dialog modal-xl">
                                                    <div className="modal-content">
                                                        <div className="modal-body p-0">
                                                            <img src={item.imageUrl2} alt=""
                                                                className="img-fluid w-100" loading="lazy" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-center visit-modal-btn-main ">
                                                    <Link to={item.url} className="btn rounded-pill visit-modal-btn"
                                                        target="_blank">Visit
                                                        Website</Link>
                                                </div>
                                            </div>
                                            {/* <!-- Modal --> */}
                                        </div>
                                    ))
                                    }
                                </div>
                            </div>
                        </main>
                        <div className='mt-5'>
                            <Footer4 />
                        </div>
                    </>
                )}
            </div>


        </>
    )
}

export default Bodycare;
