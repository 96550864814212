import { Route, Routes } from 'react-router-dom';
import './App.css';
import Main from './Main';
import Shopify from './Shopify';
import Ecommerce from './Components/Ecommerce';
import Fasion from './Components/Fasion';
import Pets from './Components/Pets';
import Bodycare from './Components/Bodycare';
import Jewellery from './Components/Jewellery';
import Health from './Components/Health';
import Fitness from './Components/Fitness';
import Wine from './Components/Wine';
import Watch from './Components/Watch';
import Shoes from './Components/Shoes';
import Electronic from './Components/Electronic';
import Food from './Components/Food';
import Tools from './Components/Tools';
import Agriculture from './Components/Agriculture';
import Sports from './Components/Sports';
import Stationery from './Components/Stationery';
import Eyewear from './Components/Eyewear';


function App() {
  return (
    <>
      {/* <BrowserRouter> */}
      <Routes>
        <Route path='/' element={<Main />} />
        <Route path='/shopify' element={<Shopify />} />
        <Route path='/e-commerce' element={<Ecommerce />} />
        <Route path='/fasion' element={<Fasion />} />
        <Route path='/pets' element={<Pets />} />
        <Route path='/bodycare' element={<Bodycare />} />
        <Route path='/Jewelry' element={<Jewellery />} />
        <Route path='/health' element={<Health />} />
        <Route path='/fitness' element={<Fitness />} />
        <Route path='/wine' element={<Wine />} />
        <Route path='/watch' element={<Watch />} />
        <Route path='/shoes' element={<Shoes />} />
        <Route path='/electronic' element={<Electronic />} />
        <Route path='/food' element={<Food />} />
        <Route path='/tools' element={<Tools />} />
        <Route path='/agriculture' element={<Agriculture />} />
        <Route path='/sports' element={<Sports />} />
        <Route path='/stationery' element={<Stationery />} />
        <Route path='/eyewear' element={<Eyewear />} />
      </Routes>
      {/* </BrowserRouter> */}
    </>
  );
}

export default App;
