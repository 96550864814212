import React, { useEffect, useState } from 'react'
import Header from './Header';
import { Link } from 'react-router-dom';
import Footer4 from './Footer4';

function Fasion() {


    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            setTimeout(() => {
                setIsLoading(false);
            }, 1000);
        };
        fetchData();
    }, []);


    const items = [
        { id: 3, name: 'Rumor Trunk', imageUrl: './images/cards/12-Rumor-Trunk-Card.jpg', imageUrl2: './images/Card-modal/12-Rumor-Trunk-Modal.jpg', url: 'https://rumortrunk.com/' },
        { id: 18, name: 'Passenger Clothing', imageUrl: "./images/cards/20-Passenger-Card.jpg", imageUrl2: "./images/Card-modal/20-Passenger-Modal.jpg", url: 'https://row.passenger-clothing.com/' },
        { id: 24, name: 'Bella Miu', imageUrl: "./images/cards/26-Bella-Miu-Card.jpg", imageUrl2: "./images/Card-modal/26-Bella-Miu-Modal.jpg", url: 'https://www.bellamiu.com/' },
        { id: 37, name: 'Ghostly Distribution', imageUrl: "./images/cards/34-Ghostly-Distribution-Card.jpg", imageUrl2: "./images/Card-modal/34-Ghostly-Distribution-Modal.jpg", url: 'https://ghostlydistribution.com/' },
        { id: 36, name: 'Free Spirit Outfits', imageUrl: "./images/cards/27-Free-Spirit-Outfits-Card.jpg", imageUrl2: "./images/Card-modal/27-Free-Spirit-Outfits-Modal.jpg", url: 'https://freespiritoutfits.com/' },
        { id: 39, name: 'Rains', imageUrl: "./images/cards/39.jpg", imageUrl2: "./images/Card-modal/39-modal.png", url: 'https://www.rains.com/' },
        { id: 42, name: 'Misocks', imageUrl: "./images/cards/42.jpg", imageUrl2: "./images/Card-modal/42-modal.jpg", url: 'https://misocks.co/' },
        { id: 44, name: 'Bella Miu', imageUrl: "./images/cards/44.jpg", imageUrl2: "./images/Card-modal/44-modal.jpg", url: 'https://www.bellamiu.com/' },
        { id: 45, name: 'Free Spirit Outfits', imageUrl: "./images/cards/45.jpg", imageUrl2: "./images/Card-modal/45-modal.jpg", url: 'https://freespiritoutfits.com/' },
        { id: 46, name: 'ilfe', imageUrl: "./images/cards/46.jpg", imageUrl2: "./images/Card-modal/46-modal.jpg", url: 'https://www.ilfe.co/' },
        { id: 50, name: 'Shopmpac', imageUrl: "./images/cards/50.jpg", imageUrl2: "./images/Card-modal/50-modal.jpg", url: 'https://shopmpac.com/' },
        { id: 60, name: 'Beaa', imageUrl: "./images/cards/60.jpg", imageUrl2: "./images/Card-modal/60-modal.jpg", url: 'https://beaa.eu/' },
        { id: 63, name: 'Omgmiamiswimwear', imageUrl: "./images/cards/63.jpg", imageUrl2: "./images/Card-modal/63-modal.png", url: 'https://www.omgmiamiswimwear.com/' },
    ];


    return (
        <>

            <div>
                {isLoading ? (
                    <div id="loader-main">
                        <div id="loader" className="loader">
                            <div id="loader-2">
                                <div id="loader">
                                    <div l="" a="" />
                                    <div l="" b="" />
                                    <div l="" c="" />
                                    <div l="" d="" />
                                    <div l="" e="" />
                                    <div l="" f="" />
                                    <div l="" g="" />
                                    <div l="" h="" />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <>


                        <Header />

                        <div className='p-3'>
                            <Link to="/shopify"><button className="btn btn-dark" style={{ backgroundColor: "#000" }}>Back</button></Link>
                        </div>

                        <h1 className="text-center portfoil-main-head">
                            Fashion / Clothing / Apparel
                        </h1>
                        <main className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    {items.map(item => (
                                        <div key={item.id} className="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-6 card-main">
                                            {/* <!-- Card --> */}
                                            <div className="card card-main-content">
                                                <img className="card-img-top" src={item.imageUrl} alt={item.name} type="button"
                                                    data-bs-toggle="modal" data-bs-target={`#exampleModal-${item.id}`}
                                                    loading="lazy" />
                                                <div className="card-body">
                                                    <div className="card-title">Shopify</div>
                                                    <p className="card-text">
                                                        <b>{item.name}</b>
                                                    </p>
                                                </div>
                                            </div>
                                            {/* <!-- Modal --> */}
                                            <div className="modal fade p-0" id={`exampleModal-${item.id}`} tabIndex="-1"
                                                aria-labelledby={`exampleModalLabel-${item.id}`} aria-hidden="true">
                                                <div className="d-flex justify-content-end modal-btn-main">
                                                    <button type="button" className=" btn-close p-2 modal-btn m-3" data-bs-dismiss="modal"
                                                        aria-label="Close"></button>
                                                </div>
                                                <div className="modal-dialog modal-xl">
                                                    <div className="modal-content">
                                                        <div className="modal-body p-0">
                                                            <img src={item.imageUrl2} alt=""
                                                                className="img-fluid w-100" loading="lazy" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-center visit-modal-btn-main ">
                                                    <Link to={item.url} className="btn rounded-pill visit-modal-btn"
                                                        target="_blank">Visit
                                                        Website</Link>
                                                </div>
                                            </div>
                                            {/* <!-- Modal --> */}
                                        </div>
                                    ))
                                    }
                                </div>
                            </div>
                        </main>
                        <div className='mt-5'>
                            <Footer4 />
                        </div>
                    </>
                )}
            </div>

        </>
    )
}

export default Fasion;