import React from 'react';
import '../App.css';

function Footer3() {
    return (
        <>
            <footer>
                <div className="footer-copy-right3 back-dark text-center copyright-text">
                    Copyright Portfolio 2023
                </div>
            </footer>
        </>
    )
}

export default Footer3;
