import React, { useEffect, useState } from 'react'
import Header from './Header';
import Footer2 from './Footer2';
import { Link } from 'react-router-dom';

function Agriculture() {

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            setTimeout(() => {
                setIsLoading(false);
            }, 1000);
        };

        fetchData();
    }, []);

    const items = [
        { id: 40, name: 'Bright', imageUrl: "./images/cards/40.jpg", imageUrl2: "./images/Card-modal/40-modal.png", url: 'https://brightsport.com/?_ab=0&_fd=0&_sc=1&ovr_rdr=1' },
        { id: 59, name: 'Neidyparra', imageUrl: "./images/cards/59.jpg", imageUrl2: "./images/Card-modal/59-modal.png", url: 'https://neidyparra.store/' },
        { id: 62, name: 'Gymshark', imageUrl: "./images/cards/62.jpg", imageUrl2: "./images/Card-modal/62-modal.jpg", url: 'https://www.gymshark.com/' },
    ];

    return (
        <>


            <div>
                {isLoading ? (
                    <div id="loader-main">
                        <div id="loader" className="loader">
                            <div id="loader-2">
                                <div id="loader">
                                    <div l="" a="" />
                                    <div l="" b="" />
                                    <div l="" c="" />
                                    <div l="" d="" />
                                    <div l="" e="" />
                                    <div l="" f="" />
                                    <div l="" g="" />
                                    <div l="" h="" />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <>

                        <Header />

                        <div className='p-3'>
                            <Link to="/shopify"><button className="btn btn-dark" style={{ backgroundColor: "#000" }}>Back</button></Link>
                        </div>

                        <h1 className="text-center portfoil-main-head">
                            Sports
                        </h1>

                        <main className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    {items.map(item => (
                                        <div key={item.id} className="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-6 card-main">
                                            {/* <!-- Card --> */}
                                            <div className="card card-main-content">
                                                <img className="card-img-top" src={item.imageUrl} alt={item.name} type="button"
                                                    data-bs-toggle="modal" data-bs-target={`#exampleModal-${item.id}`}
                                                    loading="lazy" />
                                                <div className="card-body">
                                                    <div className="card-title">Shopify</div>
                                                    <p className="card-text">
                                                        <b>{item.name}</b>
                                                    </p>
                                                </div>
                                            </div>
                                            {/* <!-- Modal --> */}
                                            <div className="modal fade p-0" id={`exampleModal-${item.id}`} tabIndex="-1"
                                                aria-labelledby={`exampleModalLabel-${item.id}`} aria-hidden="true">
                                                <div className="d-flex justify-content-end modal-btn-main">
                                                    <button type="button" className=" btn-close p-2 modal-btn m-3" data-bs-dismiss="modal"
                                                        aria-label="Close"></button>
                                                </div>
                                                <div className="modal-dialog modal-xl">
                                                    <div className="modal-content">
                                                        <div className="modal-body p-0">
                                                            <img src={item.imageUrl2} alt=""
                                                                className="img-fluid w-100" loading="lazy" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-center visit-modal-btn-main ">
                                                    <Link to={item.url} className="btn rounded-pill visit-modal-btn"
                                                        target="_blank">Visit
                                                        Website</Link>
                                                </div>
                                            </div>
                                            {/* <!-- Modal --> */}
                                        </div>
                                    ))
                                    }
                                </div>
                            </div>
                        </main>
                        <div className='mt-5'>
                            <Footer2 />
                        </div>
                    </>
                )}
            </div>


        </>
    )
}

export default Agriculture
