import React, { useEffect, useState } from 'react'
import Header from './Header';
import { Link } from 'react-router-dom';
import Footer4 from './Footer4';

function Food() {

    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            setTimeout(() => {
                setIsLoading(false);
            }, 1000);
        };
        fetchData();
    }, []);

    const items = [
        { id: 4, name: 'Wilde Protein Chips', imageUrl: './images/cards/19-Wilde-Protein-Chips-Card.jpg', imageUrl2: './images/Card-modal/19-Wilde-Protein-Chips-Modal.jpg', url: 'https://www.wildebrands.com/' },
        { id: 10, name: 'Chifles Chips', imageUrl: './images/cards/04-Chifles-Chips-Card.jpg', imageUrl2: './images/Card-modal/04-Chifles-Chips-Modal.jpg', url: 'https://chifleschips.com/' },
        { id: 17, name: 'Frozen Garden', imageUrl: "./images/cards/10-Frozen-Garden-Card.jpg", imageUrl2: "./images/Card-modal/10-Frozen-Garden-Modal.jpg", url: 'https://www.thefrozengarden.com/' },
        { id: 21, name: 'Only Mine Modal', imageUrl: "./images/cards/08-Only-Mine-Card.jpg", imageUrl2: "./images/Card-modal/08-Only-Mine-Modal.jpg", url: 'https://onlymine.com.au/' },
        { id: 22, name: 'Molly J Card', imageUrl: "./images/cards/11-Molly-J-Card.jpg", imageUrl2: "./images/Card-modal/11-Molly-J-Modal.jpg", url: 'https://mollyj.life/' },
        { id: 31, name: 'Mr Steak Card', imageUrl: "./images/cards/21-Mr-Steak-Card.jpg", imageUrl2: "./images/Card-modal/21-Mr-Steak-Modal.jpg", url: 'https://mrsteak.com/' },
        { id: 51, name: 'Blackoutwicks', imageUrl: "./images/cards/51.jpg", imageUrl2: "./images/Card-modal/51-modal.jpg", url: 'https://blackoutwicks.com/' },
    ];


    return (
        <>
            <div>
                {isLoading ? (
                    <div id="loader-main">
                        <div id="loader" className="loader">
                            <div id="loader-2">
                                <div id="loader">
                                    <div l="" a="" />
                                    <div l="" b="" />
                                    <div l="" c="" />
                                    <div l="" d="" />
                                    <div l="" e="" />
                                    <div l="" f="" />
                                    <div l="" g="" />
                                    <div l="" h="" />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <div>
                            {isLoading ? (
                                <div id="loader-main">
                                    <div id="loader" className="loader">
                                        <div id="loader-2">
                                            <div id="loader">
                                                <div l="" a="" />
                                                <div l="" b="" />
                                                <div l="" c="" />
                                                <div l="" d="" />
                                                <div l="" e="" />
                                                <div l="" f="" />
                                                <div l="" g="" />
                                                <div l="" h="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <Header />

                                    <div className='p-3'>
                                        <Link to="/shopify"><button className="btn btn-dark" style={{ backgroundColor: "#000" }}>Back</button></Link>
                                    </div>

                                    <h1 className="text-center portfoil-main-head">
                                        Food
                                    </h1>
                                    <main className="content">
                                        <div className="container-fluid">
                                            <div className="row">
                                                {items.map(item => (
                                                    <div key={item.id} className="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-6 card-main">
                                                        {/* <!-- Card --> */}
                                                        <div className="card card-main-content">
                                                            <img className="card-img-top" src={item.imageUrl} alt={item.name} type="button"
                                                                data-bs-toggle="modal" data-bs-target={`#exampleModal-${item.id}`}
                                                                loading="lazy" />
                                                            <div className="card-body">
                                                                <div className="card-title">Shopify</div>
                                                                <p className="card-text">
                                                                    <b>{item.name}</b>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        {/* <!-- Modal --> */}
                                                        <div className="modal fade p-0" id={`exampleModal-${item.id}`} tabIndex="-1"
                                                            aria-labelledby={`exampleModalLabel-${item.id}`} aria-hidden="true">
                                                            <div className="d-flex justify-content-end modal-btn-main">
                                                                <button type="button" className=" btn-close p-2 modal-btn m-3" data-bs-dismiss="modal"
                                                                    aria-label="Close"></button>
                                                            </div>
                                                            <div className="modal-dialog modal-xl">
                                                                <div className="modal-content">
                                                                    <div className="modal-body p-0">
                                                                        <img src={item.imageUrl2} alt=""
                                                                            className="img-fluid w-100" loading="lazy" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex justify-content-center visit-modal-btn-main ">
                                                                <Link to={item.url} className="btn rounded-pill visit-modal-btn"
                                                                    target="_blank">Visit
                                                                    Website</Link>
                                                            </div>
                                                        </div>
                                                        {/* <!-- Modal --> */}
                                                    </div>
                                                ))
                                                }
                                            </div>
                                        </div>
                                    </main>
                                    <div className='mt-5'>
                                        <Footer4 />
                                    </div>
                                </>
                            )}
                        </div>
                    </>
                )}
            </div>

        </>
    )
}

export default Food
