import React, { useEffect, useState } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import Header from './Components/Header';
import Footer4 from './Components/Footer4';
import './App.css';
import { Link } from 'react-router-dom';


function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, }}
      onClick={onClick}
    >
      <div style={{ borderRadius: "50%", width: "25px", boxShadow: "0 2px 50px 0 rgba(0,0,0,.40)" }}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M13.1714 12.0007L8.22168 7.05093L9.63589 5.63672L15.9999 12.0007L9.63589 18.3646L8.22168 16.9504L13.1714 12.0007Z"></path></svg>
      </div>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, }}
      onClick={onClick}
    >
      <div style={{ borderRadius: "50%", width: "25px", boxShadow: "0 2px 50px 0 rgba(0,0,0,.40)" }}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z"></path></svg>
      </div>
    </div>
  );
}


function Shopify() {

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };

    fetchData();
  }, []);

  const options = {
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 2,
    variableWidth: true,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          arrows: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          arrows: true,
          slidesToScroll: 2
        }
      }
    ]
  };

  const items = [
    { id: 40, name: 'Bright', imageUrl: "./images/cards/40.jpg", imageUrl2: "./images/Card-modal/40-modal.png", url: 'https://brightsport.com/?_ab=0&_fd=0&_sc=1&ovr_rdr=1' },
    { id: 39, name: 'Rains', imageUrl: "./images/cards/39.jpg", imageUrl2: "./images/Card-modal/39-modal.png", url: 'https://www.rains.com/' },
    { id: 4, name: 'Wilde Protein Chips', imageUrl: './images/cards/19-Wilde-Protein-Chips-Card.jpg', imageUrl2: './images/Card-modal/19-Wilde-Protein-Chips-Modal.jpg', url: 'https://www.wildebrands.com/' },
    { id: 30, name: 'Act+Acre', imageUrl: "./images/cards/15-Act+Acre-Card.jpg", imageUrl2: "./images/Card-modal/15-Act+Acre-Modal.jpg", url: 'https://actandacre.com/' },
    { id: 2, name: 'Farmers Juice', imageUrl: './images/cards/36-Farmers-Juice-Card.jpg', imageUrl2: './images/Card-modal/36-Farmers-Juice-Modal.jpg', url: 'https://farmersjuice.com' },
    { id: 32, name: 'Mojo Modal', imageUrl: "./images/cards/24-Mojo-Card.jpg", imageUrl2: "./images/Card-modal/24-Mojo-Modal.jpg", url: 'https://mojo.shop/' },
    { id: 62, name: 'Gymshark', imageUrl: "./images/cards/62.jpg", imageUrl2: "./images/Card-modal/62-modal.jpg", url: 'https://www.gymshark.com/' },
    { id: 8, name: 'Hukko Copenhagen', imageUrl: './images/cards/33-Hukko-Card.jpg', imageUrl2: './images/Card-modal/33-Hukko-Modal.jpg', url: 'https://hukkocopenhagen.com/' },
    { id: 27, name: 'Blackout Wicks', imageUrl: "./images/cards/32-Blackout-Wicks-Card.jpg", imageUrl2: "./images/Card-modal/32-Blackout-Wicks-Modal.jpg", url: 'https://blackoutwicks.com/' },
    { id: 28, name: 'Drop FX', imageUrl: "./images/cards/13-Drop-FX-Card.jpg", imageUrl2: "./images/Card-modal/13-Drop-FX-Modal.jpg", url: 'https://dropfx.com/' },
    { id: 64, name: 'Matrix Eyewear', imageUrl: "./images/cards/64.jpg", imageUrl2: "./images/Card-modal/64-modal.png", url: 'https://www.matrixeyewear.com.au/' },
    { id: 14, name: 'Medawar', imageUrl: "./images/cards/18-Medawar's-Card.jpg", imageUrl2: "./images/Card-modal/18-Medawar's-Modal.jpg", url: 'https://www.medawars.com/' },
    { id: 9, name: 'DNA Footwear', imageUrl: './images/cards/16-DNA-Footwear-Card.jpg', imageUrl2: './images/Card-modal/16-DNA-Footwear-Modal.jpg', url: 'https://www.dnafootwear.com/' },
    { id: 11, name: 'The Bra Lab', imageUrl: './images/cards/05-The-Bra-Lab-Card.jpg', imageUrl2: './images/Card-modal/05-The-Bra-Lab-Modal.jpg', url: 'https://thebralab.com/' },
    { id: 10, name: 'Chifles Chips', imageUrl: './images/cards/04-Chifles-Chips-Card.jpg', imageUrl2: './images/Card-modal/04-Chifles-Chips-Modal.jpg', url: 'https://chifleschips.com/' },
    { id: 16, name: 'Vue Swiss', imageUrl: "./images/cards/07-Vue-Swiss-Card.jpg", imageUrl2: "./images/Card-modal/07-Vue-Swiss-Modal.jpg", url: '/' },
    { id: 61, name: 'Corazi', imageUrl: "./images/cards/61.jpg", imageUrl2: "./images/Card-modal/61-modal.jpg", url: 'https://www.corazi.com/' },
    { id: 3, name: 'Rumor Trunk', imageUrl: './images/cards/12-Rumor-Trunk-Card.jpg', imageUrl2: './images/Card-modal/12-Rumor-Trunk-Modal.jpg', url: 'https://rumortrunk.com/' },
    { id: 21, name: 'Only Mine', imageUrl: "./images/cards/08-Only-Mine-Card.jpg", imageUrl2: "./images/Card-modal/08-Only-Mine-Modal.jpg", url: 'https://onlymine.com.au/' },
    { id: 26, name: 'Inspired', imageUrl: "./images/cards/09-Inspired-Card.jpg", imageUrl2: "./images/Card-modal/09-Inspired-Modal.jpg", url: 'https://www.inspirednutraceuticals.com.au/' },
    { id: 18, name: 'Passenger Clothing', imageUrl: "./images/cards/20-Passenger-Card.jpg", imageUrl2: "./images/Card-modal/20-Passenger-Modal.jpg", url: 'https://row.passenger-clothing.com/' },
    { id: 43, name: 'Wintour', imageUrl: "./images/cards/43.jpg", imageUrl2: "./images/Card-modal/43-modal.jpg", url: 'https://wintour.uk/' },
    { id: 41, name: 'Bright', imageUrl: "./images/cards/41.jpg", imageUrl2: "./images/Card-modal/41-modal.jpg", url: '/' },
    { id: 60, name: 'Beaa', imageUrl: "./images/cards/60.jpg", imageUrl2: "./images/Card-modal/60-modal.jpg", url: 'https://beaa.eu/' },
    { id: 12, name: 'Bullionb Diamond', imageUrl: './images/cards/38-Bullion-Diamond-Card.jpg', imageUrl2: './images/Card-modal/38-Bullion-Diamond-Modal.jpg', url: 'https://www.bulliondiamond.com/' },
    { id: 57, name: 'Chivewholesale', imageUrl: "./images/cards/57.jpg", imageUrl2: "./images/Card-modal/57-modal.png", url: 'https://chivewholesale.com/' },
    { id: 1, name: 'Forchics', imageUrl: './images/cards/01-Forchics-Card.jpg', imageUrl2: './images/Card-modal/01-Forchics-Modal.jpg', url: 'https://forchics.com/' },
    { id: 5, name: 'Air Plant Shop', imageUrl: './images/cards/17-Air-Plant-Shop-Card.jpg', imageUrl2: './images/Card-modal/17-Air-Plant-Shop-Modal.jpg', url: 'https://airplantshop.com/' },
    { id: 6, name: 'Tech Spade Card', imageUrl: './images/cards/30-Tech-Spade-Card.jpg', imageUrl2: './images/Card-modal/30-Tech-Spade-Modal.jpg', url: 'https://techspade.com/' },
    { id: 7, name: 'Venom Scent', imageUrl: './images/cards/02-venomscent-Card.jpg', imageUrl2: './images/Card-modal/02-venomscent-Modal.jpg', url: 'https://venomscent.com/' },
    { id: 13, name: 'Snug Soul', imageUrl: './images/cards/28-Snug-Soul-Card.jpg', imageUrl2: './images/Card-modal/28-Snug-Soul-Modal.jpg', url: 'https://snugsoul.com/' },
    { id: 17, name: 'Frozen Garden', imageUrl: "./images/cards/10-Frozen-Garden-Card.jpg", imageUrl2: "./images/Card-modal/10-Frozen-Garden-Modal.jpg", url: 'https://www.thefrozengarden.com/' },
    { id: 19, name: 'Bleame', imageUrl: "./images/cards/06-Bleame-Card.jpg", imageUrl2: "./images/Card-modal/06-Bleame-Modal.jpg", url: 'https://bleame.com/' },
    { id: 20, name: 'Meow Meow Tweet', imageUrl: "./images/cards/37-Meow-Meow-Tweet-Card.jpg", imageUrl2: "./images/Card-modal/37-Meow-Meow-Tweet-Modal.jpg", url: 'https://meowmeowtweet.com/' },
    { id: 22, name: 'Molly J Card', imageUrl: "./images/cards/11-Molly-J-Card.jpg", imageUrl2: "./images/Card-modal/11-Molly-J-Modal.jpg", url: 'https://mollyj.life/' },
    { id: 23, name: 'Arcadian Living Modal', imageUrl: "./images/cards/29-Arcadian-Living-Card.jpg", imageUrl2: "./images/Card-modal/29-Arcadian-Living-Modal.jpg", url: 'https://www.arcadianliving.com.au/' },
    { id: 24, name: 'Bella Miu', imageUrl: "./images/cards/26-Bella-Miu-Card.jpg", imageUrl2: "./images/Card-modal/26-Bella-Miu-Modal.jpg", url: 'https://www.bellamiu.com/' },
    { id: 25, name: 'JerkFit', imageUrl: "./images/cards/14-Jerk-Fit-Card.jpg", imageUrl2: "./images/Card-modal/14-Jerk-Fit-Modal.jpg", url: 'https://jerkfit.com/' },

    { id: 29, name: 'Orbit Nutrition', imageUrl: "./images/cards/25-Orbit-Nutrition-Card.jpg", imageUrl2: "./images/Card-modal/25-Orbit-Nutrition-Modal.jpg", url: 'https://www.orbitnutrition.net/' },
    { id: 31, name: 'Mr Steak Card', imageUrl: "./images/cards/21-Mr-Steak-Card.jpg", imageUrl2: "./images/Card-modal/21-Mr-Steak-Modal.jpg", url: 'https://mrsteak.com/' },
    { id: 33, name: 'Zubi Fashion', imageUrl: "./images/cards/31-Zubi-Fashion-Card.jpg", imageUrl2: "./images/Card-modal/31-Zubi-Fashion-Modal.jpg", url: 'https://zubifashion.in/' },
    { id: 34, name: 'Frame My Pet', imageUrl: "./images/cards/35-Frame-My-Pet-Card.jpg", imageUrl2: "./images/Card-modal/35-Frame-My-Pet-Modal.jpg", url: 'https://framemypet.in/' },
    { id: 35, name: 'Tecovas', imageUrl: "./images/cards/23-Tecovas-Card.jpg", imageUrl2: "./images/Card-modal/23-Tecovas-Modal.jpg", url: 'https://www.tecovas.com/' },
    { id: 36, name: 'Free Spirit Outfits', imageUrl: "./images/cards/27-Free-Spirit-Outfits-Card.jpg", imageUrl2: "./images/Card-modal/27-Free-Spirit-Outfits-Modal.jpg", url: 'https://freespiritoutfits.com/' },
    { id: 38, name: 'Swelly', imageUrl: "./images/cards/22-Swelly-Card.jpg", imageUrl2: "./images/Card-modal/22-Swelly-Modal.jpg", url: 'https://liveswelly.com/' },

    { id: 42, name: 'Misocks', imageUrl: "./images/cards/42.jpg", imageUrl2: "./images/Card-modal/42-modal.jpg", url: 'https://misocks.co/' },
    { id: 44, name: 'Bella Miu', imageUrl: "./images/cards/44.jpg", imageUrl2: "./images/Card-modal/44-modal.jpg", url: 'https://www.bellamiu.com/' },
    { id: 45, name: 'Free Spirit Outfits', imageUrl: "./images/cards/45.jpg", imageUrl2: "./images/Card-modal/45-modal.jpg", url: 'https://freespiritoutfits.com/' },
    { id: 46, name: 'ilfe', imageUrl: "./images/cards/46.jpg", imageUrl2: "./images/Card-modal/46-modal.jpg", url: 'https://www.ilfe.co/' },
    { id: 47, name: 'Snugsoul', imageUrl: "./images/cards/47.jpg", imageUrl2: "./images/Card-modal/47-modal.jpg", url: 'https://snugsoul.com/' },
    { id: 48, name: 'Arcadianliving', imageUrl: "./images/cards/48.jpg", imageUrl2: "./images/Card-modal/48-modal.jpg", url: 'https://www.arcadianliving.com.au/' },
    { id: 49, name: 'Jadeabby', imageUrl: "./images/cards/49.jpg", imageUrl2: "./images/Card-modal/49-modal.jpg", url: 'https://jadeabby.com/' },
    { id: 50, name: 'Shopmpac', imageUrl: "./images/cards/50.jpg", imageUrl2: "./images/Card-modal/50-modal.jpg", url: 'https://shopmpac.com/' },
    { id: 51, name: 'Blackoutwicks', imageUrl: "./images/cards/51.jpg", imageUrl2: "./images/Card-modal/51-modal.jpg", url: 'https://blackoutwicks.com/' },
    { id: 52, name: 'Jajaaracosmetics', imageUrl: "./images/cards/52.jpg", imageUrl2: "./images/Card-modal/52-modal.jpg", url: 'https://jajaaracosmetics.com/' },
    { id: 53, name: 'Hukkocopenhagen', imageUrl: "./images/cards/53.jpg", imageUrl2: "./images/Card-modal/53-modal.jpg", url: 'https://hukkocopenhagen.com/' },
    { id: 54, name: 'littlekinggoods', imageUrl: "./images/cards/54.jpg", imageUrl2: "./images/Card-modal/54-modal.jpg", url: 'https://littlekinggoods.com/' },
    { id: 55, name: 'Nudestix', imageUrl: "./images/cards/55.jpg", imageUrl2: "./images/Card-modal/55-modal.jpg", url: 'https://www.nudestix.com/' },
    { id: 58, name: 'Luluave', imageUrl: "./images/cards/58.jpg", imageUrl2: "./images/Card-modal/58-modal.png", url: 'https://www.luluave.com/' },
    { id: 59, name: 'Neidyparra', imageUrl: "./images/cards/59.jpg", imageUrl2: "./images/Card-modal/59-modal.png", url: 'https://neidyparra.store/' },
    { id: 63, name: 'Omgmiamiswimwear', imageUrl: "./images/cards/63.jpg", imageUrl2: "./images/Card-modal/63-modal.png", url: 'https://www.omgmiamiswimwear.com/' },
];

  return (
    <>
      <div>
        {isLoading ? (
          <div id="loader-main">
            <div id="loader" className="loader">
              <div id="loader-2">
                <div id="loader">
                  <div l="" a="" />
                  <div l="" b="" />
                  <div l="" c="" />
                  <div l="" d="" />
                  <div l="" e="" />
                  <div l="" f="" />
                  <div l="" g="" />
                  <div l="" h="" />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>

            <Header />
            <h1 className="text-center portfoil-main-head">
              PORTFOLIO
            </h1>

            <div className="container-fluid">
              <header className="header tab-view-buttons">
                <div className="filter d-flex justify-content-center">
                  <Link to="/" className="button btn rounded-pill button tab-button active"
                    data-filter="all">All</Link>
                  <Link to="/shopify" className="button btn rounded-pill button tab-button2"
                    data-filter="shopify">Shopify</Link>
                </div>
              </header>
            </div>

            <div className="container-fluid px-5">
              <Slider {...options} className='px-2'>
                <div className='box'>
                  <Link to="/e-commerce"
                    className="btn btn-dark rounded-pill mx-1 slider-button">E-commerce</Link>
                </div>
                <div className='box'>
                  <Link to="/fasion"
                    className="btn btn-dark rounded-pill mx-1 slider-button">Fashion /
                    Clothing / Apparel</Link>
                </div>
                <div className='box'>
                  <Link to="/pets"
                    className="btn btn-dark rounded-pill mx-1 slider-button">Pets
                    /Animals</Link>
                </div>
                <div className='box'>
                  <Link to="/bodycare"
                    className="btn btn-dark rounded-pill mx-1 slider-button">Body Care
                    / Skin / Cosmetic / Beauty / Body Massage</Link>
                </div>
                <div className='box'>
                  <Link to="/jewelry"
                    className="btn btn-dark rounded-pill mx-1 slider-button">Jewelry</Link>
                </div>
                <div className='box'>
                  <Link to="/health"
                    className="btn btn-dark rounded-pill mx-1 slider-button">Health Care
                    / Hospital</Link>
                </div>
                <div className='box'>
                  <Link to="/fitness"
                    className="btn btn-dark rounded-pill mx-1 slider-button">Fitness /
                    Gym</Link>
                </div>
                <div className='box'>
                  <Link to="/wine"
                    className="btn btn-dark rounded-pill mx-1 slider-button">Wine /
                    Drink /
                    Juice</Link>
                </div>
                <div className='box'>
                  <Link to="/watch"
                    className="btn btn-dark rounded-pill mx-1 slider-button">Watch</Link>
                </div>
                <div className='box'>
                  <Link to="/shoes"
                    className="btn btn-dark rounded-pill mx-1 slider-button">Shoes
                    /
                    Sneaker</Link>
                </div>
                <div className='box'>
                  <Link to="/electronic"
                    className="btn btn-dark rounded-pill mx-1 slider-button">Electronics /
                    Accessories /
                    Computer</Link>
                </div>
                <div className='box'>
                  <Link to="/food"
                    className="btn btn-dark rounded-pill mx-1 slider-button">Food /
                    Restaurants</Link>
                </div>
                <div className='box'>
                  <Link to="/tools"
                    className="btn btn-dark rounded-pill mx-1 slider-button">Tools</Link>
                </div>
                <div className='box'>
                  <Link to="/agriculture"
                    className="btn btn-dark rounded-pill mx-1 slider-button">Agriculture /
                    Farming</Link>
                </div>
                <div className='box'>
                  <Link to="/sports"
                    className="btn btn-dark rounded-pill mx-1 slider-button">Sports</Link>
                </div>
                <div className='box'>
                  <Link to="/stationery"
                    className="btn btn-dark rounded-pill mx-1 slider-button">Stationery</Link>
                </div>
                <div className='box'>
                  <Link to="/eyewear"
                    className="btn btn-dark rounded-pill mx-1 slider-button">Eye Wear</Link>
                </div>
              </Slider>
            </div>

            <main className="content mt-4">
              <div className="container-fluid">
                <div className="row">
                  {items.map(item => (
                    <div key={item.id} className="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-6 card-main">
                      {/* <!-- Card --> */}
                      <div className="card card-main-content">
                        <img className="card-img-top" src={item.imageUrl} alt={item.name} type="button"
                          data-bs-toggle="modal" data-bs-target={`#exampleModal-${item.id}`}
                          loading="lazy" />
                        <div className="card-body">
                          <div className="card-title">Shopify</div>
                          <p className="card-text">
                            <b>{item.name}</b>
                          </p>
                        </div>
                      </div>
                      {/* <!-- Modal --> */}
                      <div className="modal fade p-0" id={`exampleModal-${item.id}`} tabIndex="-1"
                        aria-labelledby={`exampleModalLabel-${item.id}`} aria-hidden="true">
                        <div className="d-flex justify-content-end modal-btn-main">
                          <button type="button" className=" btn-close p-2 modal-btn m-3" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                        </div>
                        <div className="modal-dialog modal-xl">
                          <div className="modal-content">
                            <div className="modal-body p-0">
                              <img src={item.imageUrl2} alt=""
                                className="img-fluid w-100" loading="lazy" />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-center visit-modal-btn-main ">
                          <Link to={item.url} className="btn rounded-pill visit-modal-btn"
                            target="_blank">Visit
                            Website</Link>
                        </div>
                      </div>
                      {/* <!-- Modal --> */}
                    </div>
                  ))
                  }
                </div>
              </div>
            </main>
            <div className="mt-5">
              <Footer4 />
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default Shopify
