import React from 'react';
import '../App.css';

function Header() {
    return (
        <>
            <header>
                <div className="back-dark text-center header-text">
                    I DON'T JUST BUILD WEBSITE, I BUILD YOUR BUSINESS!
                </div>
            </header>
        </>
    )
}

export default Header
