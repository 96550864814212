import React, { useEffect, useState } from 'react';
import '../App.css';
import Header from './Header';
import Footer4 from './Footer4';
import { Link } from 'react-router-dom';

function Ecommerce() {

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            setTimeout(() => {
                setIsLoading(false);
            }, 1000);
        };

        fetchData();
    }, []);

    const items = [
        { id: 28, name: 'Drop FX', imageUrl: "./images/cards/13-Drop-FX-Card.jpg", imageUrl2: "./images/Card-modal/13-Drop-FX-Modal.jpg", url: 'https://dropfx.com/' },
        { id: 30, name: 'Act+Acre', imageUrl: "./images/cards/15-Act+Acre-Card.jpg", imageUrl2: "./images/Card-modal/15-Act+Acre-Modal.jpg", url: 'https://actandacre.com/' },
        { id: 32, name: 'Mojo Modal', imageUrl: "./images/cards/24-Mojo-Card.jpg", imageUrl2: "./images/Card-modal/24-Mojo-Modal.jpg", url: 'https://mojo.shop/' },
        { id: 33, name: 'Zubi Fashion', imageUrl: "./images/cards/31-Zubi-Fashion-Card.jpg", imageUrl2: "./images/Card-modal/31-Zubi-Fashion-Modal.jpg", url: 'https://zubifashion.in/' },
        { id: 34, name: 'Frame My Pet', imageUrl: "./images/cards/35-Frame-My-Pet-Card.jpg", imageUrl2: "./images/Card-modal/35-Frame-My-Pet-Modal.jpg", url: 'https://framemypet.in/' },
        { id: 37, name: 'Ghostly Distribution', imageUrl: "./images/cards/34-Ghostly-Distribution-Card.jpg", imageUrl2: "./images/Card-modal/34-Ghostly-Distribution-Modal.jpg", url: 'https://ghostlydistribution.com/' },
        { id: 48, name: 'Arcadianliving', imageUrl: "./images/cards/48.jpg", imageUrl2: "./images/Card-modal/48-modal.jpg", url: 'https://www.arcadianliving.com.au/' },
        { id: 49, name: 'Jadeabby', imageUrl: "./images/cards/49.jpg", imageUrl2: "./images/Card-modal/49-modal.jpg", url: 'https://www.arcadianliving.com.au/' },
        { id: 54, name: 'littlekinggoods', imageUrl: "./images/cards/54.jpg", imageUrl2: "./images/Card-modal/54-modal.jpg", url: 'https://littlekinggoods.com/' },
    ];

    return (
        <>

            <div>
                {isLoading ? (
                    <div id="loader-main">
                        <div id="loader" className="loader">
                            <div id="loader-2">
                                <div id="loader">
                                    <div l="" a="" />
                                    <div l="" b="" />
                                    <div l="" c="" />
                                    <div l="" d="" />
                                    <div l="" e="" />
                                    <div l="" f="" />
                                    <div l="" g="" />
                                    <div l="" h="" />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <>

                        <Header />

                        <div className='p-3'>
                            <Link to="/shopify"><button className="btn btn-dark" style={{ backgroundColor: "#000" }}>Back</button></Link>
                        </div>

                        <h1 className="text-center portfoil-main-head">
                            E-Commerce
                        </h1>

                        <main className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    {items.map(item => (
                                        <div key={item.id} className="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-6 card-main">
                                            {/* <!-- Card --> */}
                                            <div className="card card-main-content">
                                                <img className="card-img-top" src={item.imageUrl} alt={item.name} type="button"
                                                    data-bs-toggle="modal" data-bs-target={`#exampleModal-${item.id}`}
                                                    loading="lazy" />
                                                <div className="card-body">
                                                    <div className="card-title">Shopify</div>
                                                    <p className="card-text">
                                                        <b>{item.name}</b>
                                                    </p>
                                                </div>
                                            </div>
                                            {/* <!-- Modal --> */}
                                            <div className="modal fade p-0" id={`exampleModal-${item.id}`} tabIndex="-1"
                                                aria-labelledby={`exampleModalLabel-${item.id}`} aria-hidden="true">
                                                <div className="d-flex justify-content-end modal-btn-main">
                                                    <button type="button" className=" btn-close p-2 modal-btn m-3" data-bs-dismiss="modal"
                                                        aria-label="Close"></button>
                                                </div>
                                                <div className="modal-dialog modal-xl">
                                                    <div className="modal-content">
                                                        <div className="modal-body p-0">
                                                            <img src={item.imageUrl2} alt=""
                                                                className="img-fluid w-100" loading="lazy" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-center visit-modal-btn-main ">
                                                    <Link to={item.url} className="btn rounded-pill visit-modal-btn"
                                                        target="_blank">Visit
                                                        Website</Link>
                                                </div>
                                            </div>
                                            {/* <!-- Modal --> */}
                                        </div>
                                    ))
                                    }
                                </div>
                            </div>
                        </main>
                        <div className='mt-5'>
                            <Footer4 />
                        </div>
                    </>
                )}
            </div>

        </>
    )
}

export default Ecommerce
